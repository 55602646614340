<template>
  <div class="image-with-highlighted-matches-overlay" ref="root">
    <div class="loading-error" v-if="imageLoadFailed">
      <div>Sorry!</div>
      <div>This image is currently unavailable.</div>
      <div>Please refresh the page or try again later.</div>
    </div>

    <div class="ocr-area-buttons" v-if="allowAreaOcr || isModeAreaSelection">
      <button
        v-if="allowAreaOcr && !isModeAreaSelection"
        class="ocr-area-button ocr-area-start-button text-sm"
        @click="setModeAreaSelection(true)"
      >
        <key-cup>S</key-cup>Extract Text
      </button>
      <button
        v-if="isModeAreaSelection"
        class="ocr-area-button ocr-area-cancel-button text-sm"
        @click="setModeAreaSelection(false)"
      >
        <key-cup>S</key-cup> Cancel
      </button>
      <mcr-button
        v-if="isModeAreaSelection"
        class="ocr-area-confirm-button small"
        :disabled="!isAreaSelected"
        @click="confirmOcrSelection"
      >
        <key-cup>R</key-cup> Extract Selected Area
      </mcr-button>
    </div>

    <pan-zoom
      ref="panzoom"
      :options="panZoomOptions"
      :class="{panable: panable}"
      @transform="onPanZoomTransform"
      @panstart="onPanStart"
      @panend="onPanEnd"
    >
      <div class="container" ref="transformable">
        <div class="image-with-boxes" :style="imageWithBoxesStyles">
          <area-selection-overlay
            v-if="isModeAreaSelection"
            :rotate="rotate"
            :image-width="imageOffsetWidth"
            :image-height="imageOffsetHeight"
            :scale="getPanZoomScale()"
            :render-scale="getRenderedImageScale()"
            :url="imageSrc"
            ref="area-selection-overlay"
            @area-selected="onAreaSelected"
          ></area-selection-overlay>
          <img :src="imageSrc" ref="image" @load="onImageLoad" @error="onImageLoadError" class="image no-select" />
          <template v-if="showImageHighlight">
            <highlight-box
              :offset-top="imageOffsetTop"
              :vertices="vertices"
              v-for="(vertices, index) in normalizedHighlightVertices"
              :key="getHighlightKey(index)"
            ></highlight-box>
            <highlight-box
              :offset-top="imageOffsetTop"
              :vertices="vertices"
              v-for="(vertices, index) in normalizedBorderedVertices"
              :key="getBorderedKey(index)"
              type="border"
            ></highlight-box>
            <highlight-box
              :offset-top="imageOffsetTop"
              :vertices="vertices"
              v-for="(vertices, index) in normalizedSelectedVertices"
              :key="getSelecteddKey(index)"
              type="selected"
            ></highlight-box>
            <highlight-box
              :vertices="vertices"
              :offset-top="imageOffsetTop"
              v-for="(vertices, index) in normalizedHoverableVertices"
              :key="index"
              type="hover"
              @mouseover.native="onHighlightBoxHover(index, vertices)"
              @mouseleave.native="onHighlightBoxLeave(index, vertices)"
              @mousedown.native="onHighlightBoxMouseDown(index, vertices, $event)"
              @mouseup.native="onHighlightBoxClick(index, vertices, $event)"
              @touchstart.native="onHighlightBoxMouseDown(index, vertices, $event)"
              @touchend.native="onHighlightBoxClick(index, vertices, $event)"
            ></highlight-box>
          </template>
        </div>
      </div>
    </pan-zoom>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import KeyCup from '@common/elements/icons/KeyCup';
import AreaSelectionOverlay from '@common/elements/layouts/area-selection/AreaSelectionOverlay';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import HighlightBox from './HighlightBox';

const INSIGNIFICANT_DRAGGING_THRESHOLD = 5;

export default {
  props: {
    imageSrc: String,
    highlightVertices: Array,
    hoverableVertices: {type: Array, default: () => []},
    borderedVertices: {type: Array, default: () => []},
    selectedVertices: {type: Array, default: () => []},
    disablePan: {type: Boolean, default: false},
    disableZoom: {type: Boolean, default: false},
    allowAreaOcr: {type: Boolean, default: false},
    rotate: Number,
  },
  created() {
    document.addEventListener('keydown', this.onShortcutPress);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onShortcutPress);
  },
  watch: {
    ['$store.getters.windowWidthState'](newValue, oldValue) {
      this.calculateImageSizes(this.$refs.image);
    },
    imageSrc() {
      this.imageLoadFailed = false;
      this.imageLoaded = false;
    },
  },
  data() {
    return {
      imageNaturalHeight: 0,
      imageNaturalWidth: 0,
      imageOffsetHeight: 0,
      imageOffsetWidth: 0,
      imageOffsetTop: 0,
      showImageHighlight: this.highlightVertices.length,
      imageLoadFailed: false,
      imageLoaded: false,
      draggingStart: {x: 0, y: 0},
      isZoomCalculated: false,
      isModeAreaSelection: false,
      isAreaSelected: false,
    };
  },
  computed: {
    normalizedHighlightVertices() {
      return this.getNormalizedVertices(this.highlightVertices);
    },
    normalizedHoverableVertices() {
      return this.getNormalizedVertices(this.hoverableVertices);
    },
    normalizedBorderedVertices() {
      return this.getNormalizedVertices(this.borderedVertices);
    },
    normalizedSelectedVertices() {
      return this.getNormalizedVertices(this.selectedVertices);
    },
    maxZoom() {
      const maxZoomPerBreakpoint = {
        [this.$breakpoints.mobile]: 20,
        [this.$breakpoints.tablet]: 15,
      };
      for (let breakpoint of Object.keys(maxZoomPerBreakpoint)) {
        if (this.$store.getters.windowWidthState <= breakpoint) {
          return maxZoomPerBreakpoint[breakpoint];
        }
      }
      return 10;
    },
    panZoomOptions() {
      return {
        bounds: false,
        maxZoom: this.maxZoom,
        filterKey: () => {
          return true;
        },
        beforeMouseDown: this.panZoomBeforeMouseDown,
        beforeWheel: this.panZoombBeforeWheel,
        initialZoom: this.initialZoom,
      };
    },
    imageWithBoxesStyles() {
      return {
        '-webkit-transform': `rotate(${this.rotate}deg)` /* Safari and Chrome */,
        '-moz-transform': `rotate(${this.rotate}deg)` /* Firefox */,
        '-ms-transform': `rotate(${this.rotate}deg)` /* IE 9 */,
        '-o-transform': `rotate(${this.rotate}deg)` /* Opera */,
        transform: `rotate(${this.rotate}deg)`,
        opacity: this.isZoomCalculated ? 1 : 0,
        transition: 'opacity 0.2s',
      };
    },
    panable() {
      return !this.disablePan && !this.isModeAreaSelection;
    },
  },
  methods: {
    setImageHighlight(show) {
      this.showImageHighlight = show;
    },
    onImageLoad(event) {
      this.setImageHighlight(true);
      this.calculateImageSizes(this.$refs.image);
      if (!this.imageLoaded) {
        this.imageLoaded = true;
        this.$emit('image-loaded');
      }
      if (event) {
        this.$nextTick(() => {
          this.calculateInitialZoom();
        });
      }
    },
    onImageLoadError() {
      this.imageLoadFailed = true;
    },
    calculateInitialZoom() {
      if (!this.imageOffsetHeight) {
        return;
      }

      const panZoomViewportHeight = this.$refs.panzoom.$el.clientHeight;
      const panZoomViewportWidth = this.$refs.panzoom.$el.clientWidth;

      const widthRatio = panZoomViewportWidth / this.imageOffsetWidth;
      const heightRatio = panZoomViewportHeight / this.imageOffsetHeight;

      let zoom = Math.min(widthRatio, heightRatio);
      if (zoom > 1) {
        zoom = 1;
      }
      const center = {x: panZoomViewportWidth / 2, y: panZoomViewportHeight / 2};
      this.$refs.panzoom.$panZoomInstance.zoomTo(center.x, center.y, zoom);
      this.$nextTick(() => {
        this.isZoomCalculated = true;
      });
    },
    calculateImageSizes(imageElement) {
      if (!imageElement) {
        return;
      }

      this.imageNaturalHeight = imageElement.naturalHeight;
      this.imageNaturalWidth = imageElement.naturalWidth;
      this.imageOffsetHeight = imageElement.offsetHeight;
      this.imageOffsetWidth = imageElement.offsetWidth;
      this.imageOffsetTop = imageElement.offsetTop;
      this.$nextTick(() => {
        this.imageOffsetHeight = imageElement.offsetHeight;
        this.imageOffsetWidth = imageElement.offsetWidth;
      });
    },
    panZoomBeforeMouseDown() {
      if (!this.panable) {
        return true;
      }
    },
    panZoombBeforeWheel() {
      if (this.disableZoom) {
        return true;
      }
    },
    onPanZoomTransform() {
      this.$emit('transform');
    },
    onHighlightBoxMouseDown(index, vertices, event) {
      this.draggingStart = {x: event.x, y: event.y};
    },
    getNormalizedVertices(vertices) {
      const scale = this.imageOffsetHeight / this.imageNaturalHeight;
      return vertices.map(vertices =>
        vertices.map(item => {
          return {x: item.x * scale, y: item.y * scale};
        })
      );
    },
    onHighlightBoxHover(index, vertices) {
      this.$emit('box-hover', index);
    },
    onHighlightBoxLeave(index, vertices) {
      this.$emit('box-hover-end', index);
    },
    onHighlightBoxClick(index, vertices, event) {
      if (this.wasDraggingInsignificant(this.draggingStart, {x: event.x, y: event.y})) {
        this.$emit('box-click', index);
      }
    },
    getHighlightKey(index) {
      return `highlight-${index}`;
    },
    getBorderedKey(index) {
      return `bordered-${index}`;
    },
    getSelecteddKey(index) {
      return `selected-${index}`;
    },
    wasDraggingInsignificant(start, end) {
      let byX = start.x - end.x;
      let byY = start.y - end.y;
      return Math.abs(byX) < INSIGNIFICANT_DRAGGING_THRESHOLD && Math.abs(byY) < INSIGNIFICANT_DRAGGING_THRESHOLD;
    },
    onPanStart() {
      this.$emit('panstart');
    },
    onPanEnd() {
      this.$emit('panend');
    },
    setModeAreaSelection(value) {
      this.isModeAreaSelection = value;
      this.isAreaSelected = false;
      if (this.isModeAreaSelection) {
        this.showImageHighlight = false;
        this.$refs.panzoom.$panZoomInstance.pause();
        AnalyticsMainHandler.trackClickSelectToOcrEvent(getRoutePageName(this.$route));
      } else {
        this.$nextTick(() => {
          this.showImageHighlight = true;
          this.$refs.panzoom.$panZoomInstance.resume();
        });
      }
    },
    onAreaSelected() {
      this.isAreaSelected = true;
    },
    confirmOcrSelection() {
      if (this.isAreaSelected) {
        return this.$refs['area-selection-overlay'].showModal();
      }
      this.$toasted.error('Select image area to extract text.');
    },
    getPanZoomScale() {
      return this.$refs.panzoom.$panZoomInstance.getTransform().scale;
    },
    getRenderedImageScale() {
      return this.imageNaturalWidth / this.imageOffsetWidth;
    },
    onShortcutPress(event) {
      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        return;
      }
      if (event.key === 's' && this.allowAreaOcr) {
        this.setModeAreaSelection(!this.isModeAreaSelection);
      }

      if (event.key === 'r' && this.isModeAreaSelection) {
        this.confirmOcrSelection();
      }
    },
  },
  components: {AreaSelectionOverlay, HighlightBox, KeyCup, McrButton},
};
</script>

<style lang="scss" scoped>
.image-with-highlighted-matches-overlay {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.vue-pan-zoom-item {
  &.panable {
    cursor: grab;
  }

  &.panable:active {
    cursor: grabbing;
  }
}

.image-with-boxes {
  position: relative;
  display: flex;
  justify-content: center;
}

.loading-error {
  position: absolute;
  color: white;
  text-align: center;
  top: 30%;
  width: 100%;
}

.ocr-area-button {
  white-space: nowrap;
  background-color: black;
  border: 1px solid rgba(white, 0.5);
  color: #fff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 1;
  }
}
.ocr-area-buttons {
  position: absolute;
  z-index: 2;
  top: 24px;
  right: 24px;
  display: flex;

  .ocr-area-cancel-button {
    margin-right: 12px;
  }
  .keycup {
    margin-right: 8px;
  }
}
</style>
