<template>
  <div class="thumbnail-navigation">
    <div class="thumbnails">
      <div
        v-for="priority in visiblePages"
        :key="priority"
        class="thumbnail"
        :class="{active: priority === activePriority}"
        :id="getThumbnailHtmlId(priority)"
        @click="onClick({priority: priority})"
      >
        <img :data-src="getThumbnailSrc(priority)" class="lazyload" />
        <div class="page-number">{{ priority }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activePriority: Number,
    pagesCount: Number,
    getThumbnailSrc: Function, // (priority) => `url`
  },
  data() {
    return {
      halfMaxVisiblePages: 10,
      startPage: 1,
      endPage: 10,
      clientHeight: 0,
      elementHeight: 229,
    };
  },
  mounted() {
    this.updateRangeByChangePage();
    this.$nextTick(() => {
      this.elementHeight = document.getElementById(this.getThumbnailHtmlId(this.activePriority)).clientHeight;
      this.scrollToThumbnail(this.activePriority, false, true);
    });
  },
  computed: {
    visiblePages() {
      return Array.from({length: this.endPage - this.startPage + 1}, (v, k) => k + this.startPage);
    },
  },
  watch: {
    activePriority(newVal, oldVal) {
      if (oldVal && oldVal !== newVal) {
        this.updateRangeByChangePage();
        // delay to make sure the main image is loaded from memory, otherwise the animation lags on big images
        setTimeout(() => {
          this.scrollToThumbnail(newVal, true, false);
        }, 200);
      }
    },
  },
  methods: {
    updateVisibleOnScroll(e) {
      this.clientHeight = e.target.clientHeight;
      let scrollHeight = e.target.scrollHeight;
      let scrollTop = e.target.scrollTop;
      let clientHeight = e.target.clientHeight;

      let isScrollAtTop = scrollTop <= 50;
      let isScrollAtBottom = scrollTop + clientHeight + this.elementHeight >= scrollHeight;

      let currentStartPage = this.startPage;
      let currentEndPage = this.endPage;

      if (isScrollAtTop && this.startPage > 1) {
        let newStartPage = Math.max(1, this.startPage - this.halfMaxVisiblePages);
        if (newStartPage === this.startPage) {
          return;
        }
        this.startPage = newStartPage;
        this.$nextTick(() => {
          this.scrollToThumbnail(currentStartPage, false, true);
        });
      } else if (isScrollAtBottom && this.endPage < this.pagesCount) {
        let newEndPage = Math.min(this.pagesCount, this.endPage + this.halfMaxVisiblePages);
        if (newEndPage === this.endPage) {
          return;
        }
        this.endPage = newEndPage;
        this.scrollToThumbnail(currentEndPage, false, true, -this.clientHeight);
      }
    },
    onClick(thumbnail) {
      this.$emit('select', thumbnail);
    },
    getThumbnailHtmlId(priority) {
      return 'thumbnail' + priority;
    },
    updateRangeByChangePage() {
      this.startPage = Math.max(1, this.activePriority - this.halfMaxVisiblePages);
      this.endPage = Math.min(this.pagesCount, this.activePriority + this.halfMaxVisiblePages);
    },
    scrollToThumbnail: function (priority, smooth, force, offset) {
      const thumbnailHtmlId = this.getThumbnailHtmlId(priority);
      const duration = smooth ? 500 : 0;
      this.$scrollTo('#' + thumbnailHtmlId, duration, {
        container: '.viewer-sidebar',
        force: force,
        lazy: false,
        offset: offset || 0,
      });
    },
  },
  name: 'ThumbnailNavigationVertical',
};
</script>

<style lang="scss" scoped>
.thumbnail-navigation {
  overflow: hidden;

  .thumbnails {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .thumbnail {
    padding: 20px 15px 9px;
    display: flex;
    flex-grow: 0;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 200px;

    img {
      margin-bottom: 5px;
      position: relative;
      object-fit: contain;
      height: 160px;
      background: $divider-line-light;
      min-width: 90px;
    }

    &.active {
      background: $mcr-grey-tan;

      img {
        outline: 5px solid $mcr-red;
        outline-offset: -2px;
      }
    }

    &:hover {
      background: $mcr-grey-tan;
    }
  }

  .page-number {
    color: $secondary-text-color;
  }
}
</style>
