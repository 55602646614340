<template>
  <div class="viewer-dictionary">
    <close-button @click="closeDictionary" />
    <div class="dictionary-wrapper">
      <div class="dictionary-content">
        <div v-if="noWords" class="dictionary-empty">
          <h5 class="dictionary-label">Chinese Character Dictionary</h5>
          <div class="hover-prompt">
            <span class="desktop-instructions">Hover over</span><span class="mobile-instructions">Tap</span> a character
            to see a translation.
          </div>
        </div>
        <div v-for="word in words" class="character-group">
          <h4>
            {{ getCnWord(word) }} <span>{{ word.pinyin }}</span>
          </h4>
          <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>
          <div class="definition-group">
            <div v-for="(value, index) in getEnglishValues(word)" class="definition">
              <span class="definition-count">{{ index + 1 }}</span>
              <span class="definition-value">{{ value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

export default {
  props: {
    words: Array,
    loading: Boolean,
  },
  computed: {
    noWords() {
      return !this.words.length;
    },
  },
  methods: {
    closeDictionary() {
      this.$emit('close');
    },
    getCnWord(word) {
      return word.traditional && word.simplified !== word.traditional
        ? `${word.simplified} (${word.traditional})`
        : word.simplified;
    },
    getEnglishValues(word) {
      return word.english ? word.english.split('/') : [];
    },
  },
  components: {CloseButton},
};
</script>

<style lang="scss" scoped>
.viewer-dictionary {
  height: 200px;
  position: relative;
  display: flex;
  .close-btn {
    position: absolute;
    top: 6px;
    right: 20px;
    padding: 0;
  }
  .dictionary-wrapper {
    background-color: $background-color;
    padding: 24px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex: 1;
    .dictionary-content {
      @media only screen and (min-width: $breakpoint-tablet) and (orientation: landscape) {
        max-width: $reading-max-width;
      }
      flex: 1;

      .mcr-loading-indicator::v-deep {
        .spinner {
          margin: 0;
          transform: scale(0.5);
        }
      }
      .dictionary-empty {
        .dictionary-label {
          border-bottom: 1px solid $divider-line-color;
          margin-bottom: 16px;
          padding-bottom: 12px;
          color: $text-alternate-color;
          font-family: $default-font;
          font-weight: normal;
        }
        .hover-prompt {
          color: $supplemental-text-color;
          .mobile-instructions {
            display: none;
          }
          @media only screen and (max-width: $breakpoint-mobile) {
            .mobile-instructions {
              display: inline;
            }
            .desktop-instructions {
              display: none;
            }
          }
        }
      }
      .character-group {
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 28px;
        }
        h4 {
          font-family: $default-font;
          font-weight: normal;
          font-size: 28px;
          span {
            color: $text-alternate-color;
            font-size: 0.8em;
            margin-left: 2px;
          }
        }
        .definition-group {
          margin-top: 8px;
          .definition {
            margin-right: 8px;
            display: inline;

            &:first-of-type {
              margin-top: 0;
            }

            .definition-count {
              color: $supplemental-text-color;
              &::after {
                content: '. ';
              }
            }
            .definition-value {
            }

            &:not(:last-child) {
              &::after {
                content: ',';
                color: $supplemental-text-color;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile) and (orientation: portrait) {
      padding-bottom: 70px;
    }
  }
}
</style>
