<template>
  <metadata-table class="details-table">
    <template v-slot:rows>
      <details-item label="Title (pinyin)" v-if="source.title_pinyin">{{ source.title_pinyin }}</details-item>
      <details-item label="Associations" v-if="source.associations.length">
        <span class="name" v-for="association in source.associations">
          {{ association.name_en }}
        </span>
      </details-item>
      <details-item label="Cemeteries" v-if="source.cemeteries.length">
        <router-link
          v-for="cemetery in source.cemeteries"
          class="name"
          :key="cemetery.object_id"
          :to="getCemeteryRoute(cemetery)"
          >{{ cemetery.name }}
        </router-link>
      </details-item>
      <details-item label="Surnames" v-if="source.clans.length">
        <router-link
          :to="getSurnameRoute(name.name_hant || name.name_ch)"
          class="name"
          v-for="name in source.clans"
          :key="name.name_ch"
          >{{ name.name_hant || name.name_ch }}
        </router-link>
      </details-item>
      <details-item label="Ancestral Place" v-if="source.ancestral_places && source.ancestral_places.length">
        <router-link
          v-for="place in source.ancestral_places"
          :key="place.id"
          :to="getPlaceRoute(place.id, place.pinyin)"
          class="name"
          >{{ place.pinyin }}
        </router-link>
      </details-item>
      <details-item :label="placeLabel" v-if="source.place && source.place.pinyin">
        <span v-if="isTemplate">{{ source.place.pinyin }}</span>
        <router-link v-else :to="getPlaceRoute(source.place.id, source.place.pinyin)">{{
          source.place.pinyin
        }}</router-link>
      </details-item>
      <details-item label="Publication year" v-if="source.publication_year">
        <span>{{ source.publication_year }}</span>
      </details-item>
      <details-item label="Date range" v-if="source.time_period_start || source.time_period_end">
        <span> {{ source.time_period_start || '...' }}-{{ source.time_period_end || '...' }} </span>
      </details-item>
      <details-item label="Author" v-if="source.author"
        ><span>{{ source.author }}</span></details-item
      >
      <details-item label="Editor" v-if="source.editor"
        ><span>{{ source.editor }}</span></details-item
      >
      <details-item label="Publisher" v-if="source.publisher"
        ><span>{{ source.publisher }}</span></details-item
      >
      <details-item label="Page count" v-if="showPageCount">{{ source.images_count }}</details-item>
      <details-item label="Source type">{{ source.type_display }}</details-item>
      <details-item label="Contact" v-if="contactInfo" class="contact-info">{{ contactInfo }} </details-item>
      <details-item label="Link" v-if="source.external_url" v-prerender-if="'external_url'">
        <a :href="source.external_url" target="_blank" class="external-link">
          <span>View source details in partner site</span>
          <link-icon class="link-icon"></link-icon>
        </a>
      </details-item>
      <details-item :label="getCollectionsLabel()" v-if="collections.length">
        <div class="collections-list" :class="{multiple: isMultipleCollections}">
          <div v-for="collection in collections" class="collection-item">
            <router-link :key="collection.pk" :to="{name: 'collection-detail', params: {id: collection.pk}}">{{
              collection.title
            }}</router-link>
          </div>
        </div>
      </details-item>
    </template>
  </metadata-table>
</template>

<script>
import MetadataTable from '@common/elements/layouts/MetadataTable';
import detailsItem from '@common/elements/layouts/detailsItem';
import {getCemeteryDetailRoute, getPlaceDetailRoute} from '@common/utils/utils.routes';
import {getPlaceLabelBySourceType} from '@common/utils/utils.sources';
import LinkIcon from 'vue-material-design-icons/OpenInNew';

export default {
  props: {
    source: Object,
    isTemplate: {type: Boolean, default: false},
  },
  computed: {
    placeLabel() {
      return getPlaceLabelBySourceType(this.source.type);
    },
    collections() {
      if (!this.source.collections) {
        return [];
      }
      return this.source.collections.filter(collection => collection.is_active);
    },
    showPageCount() {
      return !this.source.is_external;
    },
    isMultipleCollections() {
      return this.collections.length > 1;
    },
    contactInfo() {
      return (
        this.source.providers &&
        this.source.providers.length &&
        this.source.providers.map(p => p.contact_info).join('\n')
      );
    },
  },
  methods: {
    getCollectionsLabel() {
      return this.isMultipleCollections ? 'Collections' : 'Collection';
    },
    getSurnameRoute(word) {
      return {name: 'search-tool-surname-detail', query: {word}};
    },
    getPlaceRoute(id, name) {
      return getPlaceDetailRoute(id, name);
    },
    getCemeteryRoute(cemetery) {
      return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
    },
  },
  components: {detailsItem, LinkIcon, MetadataTable},
  name: 'DetailsTable',
};
</script>

<style lang="scss" scoped>
.details-table {
  margin-top: 30px;
}

.contact-info {
  white-space: pre-line;
}

.collections-list {
  .collection-item {
    margin-bottom: 5px;
  }
  .collection-item:last-child {
    margin-bottom: 0;
  }
}

.link-icon {
  vertical-align: bottom;
  margin-left: 5px;
}
</style>
