<template>
  <base-source-details-page>
    <page-not-found slot="page-not-found"></page-not-found>
    <template v-slot:info-cta>
      <cta-block-compare-plans></cta-block-compare-plans>
    </template>
  </base-source-details-page>
</template>

<script>
import BaseSourceDetailsPage from '@common/pages/sourceDetails/BaseSourceDetailsPage';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans.vue';

import PageNotFound from '@/components/page.not.found';

export default {
  components: {
    CtaBlockComparePlans,
    BaseSourceDetailsPage,
    PageNotFound,
  },
  name: 'SourceDetailsPage',
};
</script>

<style lang="scss" scoped></style>
