<template>
  <modal
    ref="modal"
    :class="modalClasses"
    :classes="modalBoxClasses"
    :reset="true"
    :name="modalName"
    :scrollable="true"
    height="auto"
    @before-open="beforeOpen"
    @opened="onOpened"
    :draggable="isDraggable"
  >
    <div class="body">
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <admin-person-edit-form
        :form-initial-data="{is_deceased: true}"
        :form-loading="formLoading"
        :show-relatives-json-field="true"
        @save="onSave"
        @cancel="onCancel"
      ></admin-person-edit-form>
    </div>
  </modal>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import AdminPersonEditForm from '@/components/common/forms/admin/AdminPersonEditForm';

export default {
  props: {
    sourceImageId: String,
    sourceId: String,
  },
  data() {
    return {
      modalName: 'add-mention',
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonOptionsState']),
    loading() {
      return isEmpty(this.familyTreePersonOptionsState);
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
    modalClasses() {
      return this.isMobile ? '' : 'draggable_modal';
    },
    modalBoxClasses() {
      return this.isMobile ? 'clear_modal white_modal' : '';
    },
    isDraggable() {
      return !this.isMobile;
    },
  },
  methods: {
    beforeOpen() {
      if (isEmpty(this.familyTreePersonOptionsState)) {
        this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
      }
    },
    onOpened() {
      if (this.isDraggable) {
        const element = document.getElementsByClassName('v--modal-box')[0];
        this.$refs.modal.shift.left = -element.offsetLeft;
      }
    },
    onSave(data) {
      this.formLoading = true;
      this.$store.dispatch('createFamilyTreePersonAction', data.person).then(response => {
        this.$store
          .dispatch('createMentionAction', {
            personId: response.object_id,
            imageIds: [this.sourceImageId],
            sourceId: this.sourceId,
          })
          .then(() => {
            this.formLoading = false;
            this.$modal.hide(this.modalName);
            this.$toasted.success('Created!');
            let route = this.$router.resolve({name: 'person-records-detail', params: {code: response.code}});
            window.open(route.href, '_blank');
          });
      });
    },
    onCancel() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {AdminPersonEditForm},
};
</script>

<style lang="scss" scoped></style>
