<template>
  <div class="preview-unavailable">
    <div class="access-notice">The source images for this source are restricted.</div>
    <div class="access-notice">Please contact us if you wish to research them.</div>
    <mcr-button-router-link-to-pr label="Contact Us" class="fat"></mcr-button-router-link-to-pr>
  </div>
</template>

<script>
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';

export default {
  name: 'PreviewUnavailable',
  components: {McrButtonRouterLinkToPr},
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/viewer/styles/preview-unavailable.scss';
</style>
