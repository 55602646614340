<template>
  <div class="highlight-box" :class="classes" :style="style"></div>
</template>

<script>
const TYPE_VISIBLE = 'visible';
const TYPE_HOVER = 'hover';
const TYPE_BORDER = 'border';
const TYPE_SELECTED = 'selected';

export default {
  props: {
    vertices: Array,
    offsetTop: Number,
    type: {
      type: String,
      validator: value => [TYPE_VISIBLE, TYPE_HOVER, TYPE_BORDER, TYPE_SELECTED].includes(value),
      default: TYPE_VISIBLE,
    },
  },
  computed: {
    style() {
      const minY = Math.min(...this.vertices.map(v => v.y || 0));
      const maxY = Math.max(...this.vertices.map(v => v.y || 0));
      const minX = Math.min(...this.vertices.map(v => v.x || 0));
      const maxX = Math.max(...this.vertices.map(v => v.x || 0));

      const top = minY + (this.offsetTop || 0);
      const left = minX;
      const width = maxX - minX;
      const height = maxY - minY;

      const style = {
        top: top + 'px',
        left: left + 'px',
        width: width + 'px',
        height: height + 'px',
      };
      return style;
    },
    classes() {
      return {
        'is-visible': this.type === TYPE_VISIBLE,
        'is-hoverable': this.type === TYPE_HOVER,
        'is-bordered': this.type === TYPE_BORDER,
        'is-selected': this.type === TYPE_SELECTED,
      };
    },
  },
  name: 'ImageSearchHighlighingOverlay',
};
</script>

<style scoped lang="scss">
.highlight-box {
  position: absolute;
  border: 1px solid transparent;
  &.is-visible {
    background-color: $highlight-color;
    border-color: $highlight-color;
    opacity: 0.4;
  }
  &.is-hoverable {
    border-color: $link-color;
    opacity: 0;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  &.is-bordered {
    border-color: rgba($ocr-region-border-color, 1);
  }
  &.is-selected {
    &::after {
      content: '';
      border: 3px solid rgba($active-color, 0.8);
      width: 100%;
      height: 100%;
      top: -3px;
      left: -3px;
      position: absolute;
      box-sizing: content-box;
    }
  }
}
</style>
