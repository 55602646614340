export default {
  ancestral_places: [],
  associations: [],
  author: null,
  cemeteries: [],
  clans: [],
  collections: [],
  cover: '{{ cover }}',
  editor: null,
  external_url: '{{ external_url }}',
  full_title: "{{ full_title|default_if_none:'' }}",
  has_images: '{{ has_images }}',
  has_ocr: '{{ has_ocr }}',
  id: '{{ id }}',
  images_count: '{{ images_count }}',
  is_external: '{{ is_external }}',
  is_publicly_available: false,
  is_restricted: false,
  is_user_saved: false,
  mention_type_display: '{{ mention_type_display }}',
  mentions_count: '{{ mentions_count }}',
  place: {
    id: "{{ place.id|default_if_none:'' }}",
    pinyin: "{{ place.pinyin|default_if_none:'' }}",
  },
  providers: [
    {
      url: "{{ providers.0.url|default_if_none:'' }}",
      name: "{{ providers.0.name|default_if_none:'' }}",
      contact_info: "{{ providers.0.contact_info|default_if_none:'' }}",
      list_url: "{{ providers.0.list_url|default_if_none:'' }}",
    },
  ],
  publication_year: "{{ publication_year|default_if_none:'' }}",
  publisher: '{{ publisher }}',
  summary: '{{ summary }}',
  summary_preview: '{{ summary_preview }}',
  time_period_end: "{{ time_period_end|default_if_none:'' }}",
  time_period_start: "{{ time_period_start|default_if_none:'' }}",
  title_ch: "{{ title_ch|default_if_none:'' }}",
  title_en: "{{ title_en|default_if_none:'' }}",
  title_pinyin: "{{ title_pinyin|default_if_none:'' }}",
  type: '{{ type }}',
  type_display: '{{ type_display }}',
  user_has_full_access: false,
  zupu_id: null,
};
