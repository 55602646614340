<template>
  <div class="admin-controls">
    <mcr-button @click="openMentionModal">+ New Person</mcr-button>
    <mcr-button :href="adminMentionsUrl" target="_blank">View Persons on Page</mcr-button>
    <mcr-button :href="adminImageUrl" target="_blank">Edit this Page</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';

export default {
  props: {
    imageId: String,
  },
  computed: {
    adminMentionsUrl() {
      return `${process.env.VUE_APP_BE_ADMIN_BASE}/sources/mention/?source_image_id=${this.imageId}`;
    },
    adminImageUrl() {
      return `${process.env.VUE_APP_BE_ADMIN_BASE}/sources/sourceimage/${this.imageId}`;
    },
  },
  methods: {
    openMentionModal() {
      this.$modal.show('add-mention');
    },
  },
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.admin-controls {
  .mcr-button {
    background-color: $admin-color;
    margin-top: 20px;
  }
}
</style>
