<template>
  <div class="search-results">
    <div class="search-result">
      <search-results-item
        v-for="item in results"
        :key="item.priority"
        :page="item.priority"
        :snippets="item.snippets"
        :is-current-page="item.isCurrentPage"
        :preview-src="item.previewSrc"
        :preview-available="item.previewAvailable"
        @click.native.stop="onClick(item.priority)"
      ></search-results-item>
    </div>
  </div>
</template>

<script>
import SearchResultsItem from './SearchResultsItem';

export default {
  props: {
    searchQuery: String,
    results: Array,
  },
  methods: {
    onClick(pageNumber) {
      this.$emit('click', pageNumber);
    },
  },
  components: {SearchResultsItem},
};
</script>

<style lang="scss" scoped>
.search-result > * {
  margin-top: 15px;
}
</style>
