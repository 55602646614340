<template>
  <div class="search-result">
    <p class="no-results-message">No results found in this source for "{{ searchQuery }}"</p>
    <div class="no-results">
      <div class="bold">Tips:</div>
      <ul class="list">
        <li>Make sure your search only includes Chinese characters.</li>
        <li>Remove the surname for more hits.</li>
        <li>
          Switch to Traditional Chinese or Simplified Chinese characters (use
          <a :href="converterHref" target="_blank">this free tool</a> to convert your search terms).
        </li>
        <li>Try searching in other sources.</li>
      </ul>
      <em class="beta-disclaimer">This is a BETA service. There may be errors and inaccuracies in the results.</em>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    searchQuery: String,
  },
  data() {
    return {
      converterHref: 'https://www.lexilogos.com/keyboard/chinese_conversion.htm',
    };
  },
};
</script>
<style lang="scss" scoped>
.no-results-message {
  color: $supplemental-text-color;
}
.no-results {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $divider-line-color;
  ul {
    padding-left: 20px;
    border-bottom: 1px solid $divider-line-color;
    padding-bottom: 24px;
  }
  em {
    color: $supplemental-text-color;
  }
}
</style>
