<template>
  <div class="summary-text">
    <div v-html="summaryPreview || summary" class="preview"></div>
    <a v-if="summaryPreview" class="read-more" @click="showFullDescription">Read More</a>

    <modal :name="modalName" classes="clear_modal white_modal" :scrollable="true" height="auto">
      <div>
        <div class="heading">
          <div class="text-md bold">Full Description</div>
          <close-icon class="close-icon" @click="closeModal"></close-icon>
        </div>
        <div class="content" v-html="summary"></div>
      </div>
    </modal>
  </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close';

export default {
  props: {
    summary: String,
    summaryPreview: String,
  },
  data() {
    return {
      modalName: 'summary-modal',
    };
  },
  methods: {
    showFullDescription() {
      this.$modal.show(this.modalName);
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {CloseIcon},
};
</script>

<style lang="scss" scoped>
.summary-text {
  .preview {
    display: inline;

    &::v-deep > *:last-child {
      display: inline;
    }
    &::v-deep > ul:last-child {
      display: block;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .read-more {
    margin-left: 5px;
  }

  .heading {
    color: $neutral-600;
  }

  &::v-deep {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }
  }
}
</style>
