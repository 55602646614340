<template>
  <stand-out-block>
    <div class="bold">Search for someone in this source:</div>
    <div class="form">
      <input placeholder="First name" v-model="firstName" @keyup.enter="go" class="input" />
      <input placeholder="Last name" v-model="lastName" @keyup.enter="go" class="input" />
      <mcr-button @click="go">Go</mcr-button>
    </div>
    <router-link :to="searchRoute" class="view-all-link">View all persons in this source</router-link>
  </stand-out-block>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

export default {
  props: {
    searchRoute: Object,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
    };
  },
  methods: {
    go() {
      this.$emit('submit', {firstName: this.firstName, lastName: this.lastName});
    },
  },
  components: {McrButton, StandOutBlock},
  name: 'SectionSearchRecords',
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  align-items: stretch;
  margin-top: 15px;

  > input {
    flex-grow: 1;
    margin: 0 15px 0 0;
    min-width: 0;
  }
}

.view-all-link {
  display: block;
  margin-top: 18px;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .form {
    flex-direction: column;
    > *,
    > input {
      margin: 10px 0;
      width: 100%;
    }
  }

  .view-all-link {
    margin-top: 8px;
  }
}
</style>
