<template>
  <div class="search-result-item" :class="classes">
    <div class="header">
      <span>Page {{ page }} </span>
      <span v-if="previewAvailable">Preview available!</span>
    </div>
    <div class="image-container">
      <img :src="previewSrc" v-if="previewSrc" />
    </div>
    <div v-for="snippet in snippets" class="content">
      <span v-html="snippet"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    snippets: Array,
    isCurrentPage: {type: Boolean, default: false},
    previewSrc: String,
    previewAvailable: Boolean,
  },
  computed: {
    classes() {
      return {'current-page': this.isCurrentPage};
    },
  },
};
</script>

<style scoped lang="scss">
$content-background-color: $background-alternate-color;
$border-color: $border-color-light;

.search-result-item {
  cursor: pointer;
  border: 1px solid $border-color;
  transition: all 0.2s ease;
  background-color: $background-alternate-color;
  position: relative;

  &::v-deep em {
    background-color: $highlight-color;
  }

  .header {
    border-bottom: none;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    transition: all 0.1s ease;
    box-shadow: $box-shadow;
    position: relative;
    z-index: 2;
  }

  .content {
    padding: 15px;
  }

  &::v-deep .highlighted {
    background-color: $highlight-color;
  }

  &:hover {
    border-color: $link-color;
    .content {
    }
    .header {
      color: $link-color;
      text-decoration: underline;
    }
  }

  &.current-page {
    border-color: #555;
    .header {
      background-color: #555;
      color: #eee;
    }
  }

  .image-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: $photo-border-color;
    box-shadow: $box-shadow;
  }
}
</style>
