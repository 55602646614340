<template>
  <base-collection-details-page>
    <template v-slot:page-not-found>
      <page-not-found></page-not-found>
    </template>
  </base-collection-details-page>
</template>

<script>
import BaseCollectionDetailsPage from '@common/pages/sourceCollectionDetails/BaseCollectionDetailsPage';

import PageNotFound from '@/components/page.not.found';

export default {
  components: {PageNotFound, BaseCollectionDetailsPage},
  name: 'CollectionDetailsPage',
};
</script>

<style scoped></style>
