<template>
  <div class="touch-page-navigation">
    <div class="prev" :class="{disabled: !hasPrevious}" @click="touchPrevPage">&lsaquo;</div>
    <div class="next" :class="{disabled: !hasNext}" @click="touchNextPage">&rsaquo;</div>
  </div>
</template>

<script>
export default {
  props: {
    hasNext: Boolean,
    hasPrevious: Boolean,
  },
  methods: {
    touchPrevPage() {
      if (this.hasPrevious) {
        this.$emit('previous');
      }
    },
    touchNextPage() {
      if (this.hasNext) {
        this.$emit('next');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.touch-page-navigation {
  display: none;
  box-shadow: $box-shadow-light;
  border-top: 1px solid $divider-line-color;

  /* fix mobile browser header expands and moves buttons out of viewport */
  position: sticky;
  bottom: 0;
  background-color: $background-alternate-color;
  z-index: 5;

  > div {
    width: calc(50% - 0.5px);
    height: 50px;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    line-height: 0;
    position: relative;
    top: -1px;
    &:focus,
    &:active {
      background-color: $active-color;
      color: $off-white;
    }
    &:first-child {
      border-right: 1px solid $divider-line-color;
    }
    &.disabled {
      color: #ccc;
      background-color: rgba(black, 0.05);
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    display: flex;
  }
}
</style>
