<template>
  <div class="viewer-ocr-panel">
    <button class="highlight-all" @click="$emit('click-highlight-all')" :class="{'highlights-active': highlightAll}">
      Highlight All
    </button>
    <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
    <div class="no-ocr-data" v-else-if="noSymbolsData">There is no OCR data yet for this image.</div>

    <pan-zoom ref="panzoom" :options="{maxZoom: 7}">
      <div class="transformable-container" ref="transformable">
        <div class="symbols-container">
          <img :src="imageSrc" class="hidden" ref="hiddenImage" @load="onImageLoad" />
          <template v-if="scale">
            <div v-for="data in symbolsDataWithHighlight" :style="data.style" :key="data.id">
              {{ data.text }}
            </div>
          </template>
        </div>
      </div>
    </pan-zoom>
  </div>
</template>

<script>
export default {
  props: {
    ocrSymbols: Array,
    imageSrc: String,
    activeBoxIndex: Number,
    symbolsLoading: Boolean,
    highlightAll: Boolean,
  },
  data() {
    return {
      imageLoading: false,
      scale: 0,
      rendered: false,
    };
  },
  mounted() {
    this.$refs.panzoom.$panZoomInstance.pause();
  },
  watch: {
    imageSrc(newValue, oldValue) {
      this.imageLoading = true;
    },
    symbolsData(newValue) {
      if (!this.rendered && newValue.length) {
        // in case ocrSymbols length is couple of thousands, the ocr panel will be slow, so we need to re-render
        // highlighting on initial image after the ocr panel will be rendered
        this.rendered = true;
        this.$emit('mounted');
      }
    },
  },
  computed: {
    symbolsData() {
      let symbolsData = [];
      let maxWidth = 0;

      for (let index = 0; index < this.ocrSymbols.length; index++) {
        const symbol = this.ocrSymbols[index];
        const minY = Math.min(...symbol.boundingBox.vertices.map(v => v.y));
        const minX = Math.min(...symbol.boundingBox.vertices.map(v => v.x));
        const maxX = Math.max(...symbol.boundingBox.vertices.map(v => v.x));
        const width = maxX - minX;
        maxWidth = width > maxWidth ? width : maxWidth;
        symbolsData.push({
          id: index,
          style: {
            position: 'absolute',
            top: minY * this.scale + 'px',
            left: minX * this.scale + 'px',
          },
          width: width,
          ...symbol,
        });
      }
      return symbolsData;
      // .map(data => {
      // const newData = {...data}
      // let scale = data.width / maxWidth;
      // newData.style.transform = `scale(${scale}, ${scale})`
      // return newData;
      // })
    },
    symbolsDataWithHighlight() {
      return this.symbolsData.map((data, index) => {
        return {
          ...data,
          style: {
            ...data.style,
            'background-color': index === this.activeBoxIndex ? '#920005' : 'transparent',
            'z-index': index === this.activeBoxIndex ? 2 : 1,
          },
        };
      });
    },
    noSymbolsData() {
      return this.symbolsData.length === 0;
    },
    loading() {
      return (!this.scale || this.imageLoading || this.symbolsLoading) && this.noSymbolsData;
    },
  },
  methods: {
    onImageLoad() {
      this.imageLoading = false;
      this.recalculateScale();
    },
    recalculateScale() {
      if (!this.$refs.hiddenImage) {
        return;
      }
      // this.scale = 1;
      this.scale = this.$refs.hiddenImage.offsetHeight / this.$refs.hiddenImage.naturalHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.viewer-ocr-panel {
  width: 100%;
  height: 100%;
  position: relative;

  .transformable-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .symbols-container {
      position: relative;
    }

    img {
      display: flex;
    }
  }

  .hidden {
    opacity: 0;
  }

  .mcr-loading-indicator,
  .no-ocr-data {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.highlight-all {
  font-size: 0.8em;
  white-space: nowrap;
  background-color: black;
  border: 1px solid rgba(white, 0.5);
  color: #fff;
  opacity: 0.5;
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 2;
  &:hover {
    opacity: 1;
  }
  &.highlights-active {
    opacity: 1;
    border: 1px solid cyan;
    color: cyan;
  }
}
</style>
